// theme.ts

// 1. import `extendTheme` function
import { extendTheme, ThemeConfig } from "@chakra-ui/react"
import { styles } from "./styles"
// 2. Add your color mode config
const config : ThemeConfig = {
    initialColorMode: "dark",
    useSystemColorMode: false,
}


// 3. extend the theme
const theme = extendTheme({
    config,
    colors:{
        brand: {
            50: '#fff',
            100: '#ffffff',
            200: '#ffffff',
            300: '#ffffff',
            400: '#ffffff',
            500: '#ffffff',
            600: '#ffffff',
            700: '#fff',
            800: '#fff',
            900: '#000000',
        },
    },
    styles,
    fonts: {
        heading: "Space Mono",
        body: "Space Mono",
    },
})

export default theme
