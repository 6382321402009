import '@google/model-viewer';
import React, {useEffect} from "react";
import {Text} from "@chakra-ui/react";

interface ModelViewerJSX {
    src: string
}
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'model-viewer': ModelViewerJSX & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> ;
        }
    }
}
export const ModelViewer = ({ modelUrl, modelUsdzUrl, posterImage, roomId }) => {
    // console.log(modelUsdzUrl);
    // console.log(roomExperienceClaims);
    // let url = "";
    // console.log(PosterImage(posterImg));
    function RandomNumberWrapper({ setState }) {
        const number = 5;

        useEffect(() => {
            setState(number);
        }, [setState, number]);

        return null;
    }

    // @ts-ignore
    return (
        <div>
            <Text color={"white"}>
                {modelUrl !== "" &&
                    // @ts-ignore
                        <model-viewer  style={{ width: "100%", height: "600px" }} src={modelUrl} ios-src={modelUsdzUrl} poster={posterImage} alt="3d model unlit" shadow-intensity="1" loading="eager" reveal="auto" camera-controls auto-rotate ar ar-modes="webxr scene-viewer quick-look"></model-viewer>
            }
            </Text>

        </div>
    );
};