import {AuthWrapper} from "./Auth";
import {Box, Container, Flex, SimpleGrid, Text, VStack} from "@chakra-ui/react";
import {ErrorBoundary} from "react-error-boundary";
import React from "react";
import {ModelViewer} from "./ModelViewer";
// import NftCardUnavailable from "../NftCardUnavailable";

function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <div role="alert">
            {/*<NftCardUnavailable></NftCardUnavailable>*/}
            {/*<p>Something went wrong:</p>*/}
            {/*<Code background="brand.600" marginBottom="5"><pre>{error.code}</pre></Code>*/}
            {/*<Code><pre>{error.message}</pre></Code>*/}
            {/*<button onClick={resetErrorBoundary}>Try again</button>*/}
        </div>
    )
}


export const Nft = ({ modelUrl, modelUsdzUrl, roomId, artistDisplay, posterImage }) => {
    // console.log(user);
    // console.log(claims);
    // set up query
    // console.log(modelUrl, modelUsdzUrl, roomId, artistDisplay, posterImage);


    return (
        <AuthWrapper fallback={<span color={"white"}>Sign in to use this component</span>}>
                {/*{ <Grid templateColumns={`repeat(${rooms.length}, 1fr)`} gap={0}>*/}
                { <SimpleGrid columns={[2, 3, 3]} minChildWidth="340px" spacing="40px" gap={10}>
                        <Container w="100%" h="100%" overflow="hidden" borderWidth="1px" borderRadius="1" borderColor="gray.800">
                            <VStack>
                                <Box w="100%" h="100%" marginTop={4}>
                                    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {
                                        // reset the state of your app so the error doesn't happen again
                                    }}
                                    >
                                        if(modelUrl===1){
                                        <ModelViewer modelUrl={modelUrl} modelUsdzUrl={modelUsdzUrl} posterImage={posterImage} roomId={roomId}/>
                                    }
                                    </ErrorBoundary>
                                </Box>
                                <Container p="6">
                                    <Flex mt="1" justifyContent="space-between" alignContent="start" alignItems="baseline">
                                        <VStack alignItems={"start"}>
                                            <Box
                                                fontFamily="Space Mono"
                                                color="white"
                                                fontSize="lg"
                                                fontWeight="semibold"
                                                as="h4"
                                                lineHeight="tight"
                                                isTruncated>
                                                {artistDisplay}
                                            </Box>
                                            <Box
                                                fontFamily="Space Mono"
                                                color="white"
                                                fontSize="md"
                                                fontWeight="semibold"
                                                as="h4"
                                                lineHeight="tight"
                                                isTruncated>
                                                Floor: {roomId.slice(0,1)}
                                            </Box>
                                            <Box
                                                fontFamily="Space Mono"
                                                color="white"
                                                fontSize="md"
                                                fontWeight="semibold"
                                                as="h4"
                                                lineHeight="tight"
                                                isTruncated>
                                                Room: {roomId}
                                            </Box>
                                        </VStack>
                                        {/*<Tooltip
                                    label="Add to cart"
                                    bg="white"
                                    placement={'top'}
                                    color={'gray.800'}
                                    fontSize={'1.2em'}>
                                    <chakra.a href={'#'} display={'flex'}>
                                        <Icon as={FiShoppingCart} h={7} w={7} alignSelf={'center'} />
                                    </chakra.a>
                                </Tooltip>*/}
                                    </Flex>

                                    {/*<Flex justifyContent="space-between" alignContent="center">
                        <Rating rating={data.rating} numReviews={data.numReviews} />
                        <Box fontSize="2xl" color={useColorModeValue('gray.800', 'white')}>
                            <Box as="span" color={'gray.600'} fontSize="lg">
                                £
                            </Box>
                            {data.price.toFixed(2)}
                        </Box>
                    </Flex>*/}
                                </Container>
                            </VStack>
                        </Container>
                </SimpleGrid>}
        </AuthWrapper>
    );
}