export const styles = {
    global: {
        // styles for the `body`
        body: {
            // bg: "black.900",
            bg: "black",
            color: "black",
        },
        // styles for the `a`
        a: {
            color: "yellow.500",
            _hover: {
                textDecoration: "underline",
            },
        },
        p:{
            color: "white"
        }
    },
}