import React, { useState, useEffect, Suspense } from 'react';
import { useAuth, useSigninCheck } from 'reactfire';
import { WideButton } from './display/Button';
import { CardSection } from './display/Card';
import { LoadingSpinner } from './display/LoadingSpinner';
import { CircularProgress } from '@chakra-ui/react';
import { GoogleAuthProvider, signInWithPopup, signInWithCustomToken } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseConfig } from '../firebaseConfig';
import * as https from "https";
// import {ConnectButton} from "./ConnectButton";
const signOut = auth => auth.signOut().then(() => console.log('signed out'));
const signIn = async auth => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(auth, provider);
}
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, 'europe-west1');
const requestCustomToken = httpsCallable(functions, "requestCustomToken") as (params: { account: string }) => Promise<{ data: { token: string } }>;
export const addMessage = httpsCallable(functions, "addMessage") as (params: { text: string }) => Promise<{ data: { success: string } }>;

const signInCustom = async auth => {
    const requiredClaims = { canModifyPages: 'true', moderator: 'true' };
    // console.log('before customToken');
    // const { requiredClaims: requiredClaims }
    const withClaimsCustomToken = {
        uid: 'some-crypto-account-id',
        claims: requiredClaims,
    };
    const customToken = await requestCustomToken({account: "0xcfe9125bdf40b4a13b5a4a86e5db08b8dd12f6cc"});
    // console.log(customToken);

    await signInWithCustomToken(auth, customToken.data.token);
    //await waitForHookCondition(() => result.current.status === 'success');
    //expect(result.current.data.signedIn).toEqual(true);
    //expect(result.current.data.hasRequiredClaims).toEqual(true);
}

export const AuthWrapper = ({ children, fallback }: React.PropsWithChildren<{ fallback: JSX.Element }>): JSX.Element => {
    // @ts-ignore
    const { status, data: signInCheckResult } = useSigninCheck({requiredClaims: {"admin": true}});
    if (!children) {
        throw new Error('Children must be provided');
    }
    if (status === 'loading') {
        return <h1>loading@Authwrapper<LoadingSpinner /></h1>;
    } else if (signInCheckResult.signedIn === true) {
        return children as JSX.Element;
    }

    return fallback;
};

const UserDetails = ({ user }) => {
    // console.log(user);
    const auth = useAuth();
    const [claims, setClaims] = useState({});
    const [claimTest, setClaimTest] = useState("");

    useEffect(() => {
        async function getToken() {
            const tokenResult = await user.getIdTokenResult();
            console.log(tokenResult.claims.roomExperiences);
            const toJson = [{test: 1}];
            // setClaims(tokenResult);
            // setClaimTest(tokenResult.claims?.roomExperiences["501Ar"]);
        }
        getToken();
    }, [])
    return (
        <>
            <CardSection title="Displayname">{user.uid}</CardSection>
            <CardSection title="Providers">
                <h3>{JSON.stringify(claimTest)}</h3>
                <ul>
                    {user.claims?.map(profile => (
                        <li key={profile}>{profile}</li>
                    ))}
                </ul>
            </CardSection>
            <CardSection title="Firebase Auth">
                <WideButton label="Sign Out" onClick={() => signOut(auth)} />
            </CardSection>
        </>
    );
};

const SignInForm = () => {
    const auth = useAuth();
    const { status, data: signinResult } = useSigninCheck({});
    console.log(status);
    let signInClick = false;
    function setSignInClick (props){
        signInClick = true;
    }

    return (
        <CardSection title="Firebase Auth">
            { signInClick &&
                <CircularProgress isIndeterminate color="green.300" />
            }
            {/*<WideButton label="Sign in with Google" onClick={() => signIn(auth)} />*/}
            <WideButton label="Sign in with Custom Token" onClick={()=>{ signInCustom(auth); setSignInClick(true) }} />
            {/*<WideButton label="Add Message" onClick={() => addMessage({text: "test"})} />*/}
        </CardSection>
    );
};

export const Auth = () => {
    const auth = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //console.log('@moralis: isAuthenticated');
        //const test = signInCustom(auth);
        //console.log(test);
    },[]);
    // signInCustom(auth);

    //@ts-ignore
    const { status, data: signinResult } = useSigninCheck({requiredClaims: {admin: true}});
    console.log(status);
    if (status === 'loading') {
        return <LoadingSpinner />;
        // <CircularProgress isIndeterminate color="green.300" />
    }

    const { signedIn, user } = signinResult;

    if (signedIn === true) {
        return <Suspense fallback={<p>loading...</p>}><UserDetails user={user} /></Suspense>;
    } else {
        return <SignInForm />;
    }
};
