import React, {useState, Suspense} from 'react';
import {
    ChakraProvider,
    Box,
    Text,
    VStack,
    Grid,
    Button,
    Progress,
    PinInput,
    PinInputField,
    Center,
    Collapse,
    useDisclosure,
    ScaleFade
    // theme,
} from "@chakra-ui/react"
import {Logo} from "./Logo"
import Fonts from "./theme/fonts"
import "@fontsource/space-mono/700.css"
import theme from "./theme/index";
import {HiMenuAlt1} from "react-icons/all";
import BorderLength from "./components/BorderLength";
import {AuthWrapper} from "./components/Auth";
import {Nft} from "./components/Nft";
import {firebaseConfig} from './firebaseConfig'
import {FirestoreProvider, AuthProvider, useFirebaseApp} from 'reactfire';
import {getAuth, signInWithCustomToken} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getFunctions, httpsCallable} from 'firebase/functions';
import {initializeApp} from "firebase/app";
import {getStorage, ref, getDownloadURL} from "firebase/storage";

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, 'europe-west1');
const signInWithOtp = httpsCallable(functions, "signInWithOtp") as (params: { otp: string }) => Promise<{ data: { token: string, res: any } }>;

function ScaleFadeEx() {
    const { isOpen, onToggle } = useDisclosure()

    return (
        <>
            <Button onClick={onToggle}>Click Me</Button>
            <ScaleFade initialScale={0.9} in={isOpen}>
                <Box
                    p="40px"
                    color="white"
                    mt="4"
                    bg="teal.500"
                    rounded="md"
                    shadow="md"
                >
                    Fade
                </Box>
            </ScaleFade>
        </>
    )
}
function CollapseEx() {
    const {isOpen, onToggle} = useDisclosure()

    return (
        <>
            <Button onClick={onToggle}>Click Me</Button>
            <Collapse in={isOpen} animateOpacity>
                <Box
                    p="40px"
                    color="white"
                    mt="4"
                    bg="teal.500"
                    rounded="md"
                    shadow="md"
                >
                    <Logo h="40vmin" pointerEvents="none"/>
                </Box>
            </Collapse>
        </>
    )
}

export const App = () => {
    const handleChange = (value) => {
        setOtpValue(value);
    }
    const handleComplete = async (value: string) => {
        // console.log(value);
        setOtpErrMsg("");
        setOtpInvalid(false);
        await logInWithOtp(value);
    };
    const logInWithOtp = async otp => {
        try {
            setInstructionsText("Checking Otp...");
            setLoadingFromBackend(true);
            setOtpInputDisabled(true);
            const res = await signInWithOtp({otp: otp});
            // console.log("from logInWithOtp:", res.data.token);
            //const tokenRes = res.data.token;

            /* sign in with custom firebase token */
            const auth = getAuth();
            const userCredential = await signInWithCustomToken(auth, res.data.token);
            /*const user = userCredential.user;
            console.log(user);*/
            const currentUser = await auth.currentUser;

            /* getIdTokenResult for debug only*/
            // @ts-ignore
            const idTokenResult = await currentUser.getIdTokenResult();
            // console.log("@submit::getIdTokenResult::claims.roomExperiences", idTokenResult.claims.roomExperiences);

            /* getting storageUrl for Vr model */
            const storage = getStorage();
            const data = res.data.res;
            const modelUrl = await getDownloadURL(ref(storage, data.modelGltf));
            const modelUsdzUrl = await getDownloadURL(ref(storage, data.modelUsdz));
            const posterImageUrl = await getDownloadURL(ref(storage, data.posterImage));
            const roomId = data.roomId;
            const posterImage = posterImageUrl;
            const artistDisplay = data.artistDisplay
            setNftData({modelUrl, modelUsdzUrl, roomId, artistDisplay, posterImage})
            setLoadingFromBackend(false);
        } catch (error) {
            setInstructionsText(":-(");
            setLoadingFromBackend(false);
            setOtpInvalid(true)
            setOtpInputDisabled(false);
            setOtpErrMsg(error.details.message);
            setTimeout(()=>{
                setInstructionsText("Please try again.");
              setOtpInvalid(false);
              setOtpErrMsg("");
              setOtpValue("");
            }, 2500)
            switch (error.code) {
                case 'functions/failed-precondition':
                    if (error.details.message === 'No OTP available') {
                        // displayMessageAndClearInputs('Invalid code, try again:', 'red');
                    }
                    break;
                case 'storage/object-not-found':
                // File doesn't exist
                case 'storage/unauthorized':
                // User doesn't have permission to access the object
                case 'storage/canceled':
                // User canceled the upload
                case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    // showSpinner(false);
                    // showUI(true);
                    // displayMessageAndClearInputs('Server error, try again:', 'red');
                    break;
            }
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
        }
    }
    const firebaseApp = useFirebaseApp();
    const auth = getAuth(firebaseApp);
    const firestoreInstance = getFirestore(useFirebaseApp());
    const [instructionsText, setInstructionsText] = useState("Enter your OTP below.");
    const [otpInvalid, setOtpInvalid] = useState(false);
    const [otpErrMsg, setOtpErrMsg] = useState("");
    const [otpInputDisabled, setOtpInputDisabled] = useState(false);
    const [loadingFromBackend, setLoadingFromBackend] = useState(false);
    const [otpValue, setOtpValue] = useState("");
    const [nftData, setNftData] = useState({modelUrl: "", modelUsdzUrl: "", roomId: "", artistDisplay: "", posterImage: ""});

    // @ts-ignore
    return (
        <ChakraProvider theme={theme}>
            <AuthProvider sdk={auth}>
                <FirestoreProvider sdk={firestoreInstance}>

                    <Box textAlign="center" fontSize="xl">
                        <Grid minH="5vh" p={3}>
                            {/*<ColorModeSwitcher justifySelf="flex-end" />*/}
                            <VStack marginTop={20} spacing={8}>
                                {/*  <Button borderRadius="0" colorScheme="brand" variant="outline">
                    Call us
                  </Button>*/}
                                {/*<ScaleFadeEx></ScaleFadeEx>*/}
                                <Logo h="40vmin" pointerEvents="none"/>
                                {/*<CollapseEx></CollapseEx>*/}

                                {nftData.modelUrl === "" && <Text color="white">{instructionsText}</Text>}
                                <Center alignItems={"center"} alignContent={"center"} width={"100%"}>
                                    {nftData.modelUrl === "" &&
                                        <PinInput value={otpValue} manageFocus={true} isDisabled={otpInputDisabled}
                                                  isInvalid={otpInvalid} autoFocus={true} otp focusBorderColor={"white"}
                                                  size="lg" onChange={handleChange} onComplete={handleComplete}>
                                            <PinInputField focus="true" borderRadius={0} color={"white"}/>
                                            <PinInputField borderRadius={0} color={"white"}/>
                                            <PinInputField borderRadius={0} color={"white"}/>
                                            <Box border={"none"} color={"white"} alignItems={"center"}
                                                 alignContent={"center"}>
                                                <Box alignItems={"center"} alignContent={"center"}
                                                     alignSelf={"center"}><BorderLength></BorderLength></Box>
                                            </Box>
                                            <PinInputField borderRadius={0} color={"white"}/>
                                            <PinInputField borderRadius={0} color={"white"}/>
                                            <PinInputField borderRadius={0} color={"white"}/>
                                        </PinInput>
                                    }

                                </Center>
                                <Box width={"100%"}>
                                  <Progress hidden={!loadingFromBackend} colorScheme={"yellow"}
                                                              size="lg" isAnimated={true}
                                                              isIndeterminate={true}></Progress>
                                </Box>
                                {otpInvalid && <Text fontSize="sm" color="tomato">
                                    {otpErrMsg}
                                </Text>}
                            </VStack>


                        </Grid>
                        <AuthWrapper fallback={<span>Sign in to use this component</span>}>
                            <Box paddingBottom="5">
                                <div>Home Screen</div>
                                {nftData.modelUrl !== "" &&
                                    <Nft modelUrl={nftData.modelUrl} modelUsdzUrl={nftData.modelUsdzUrl} artistDisplay={nftData.artistDisplay}
                                         roomId={nftData.roomId} posterImage={nftData.posterImage}></Nft>
                                }
                                <Suspense fallback={<span>Sign in to use this component</span>}>

                                </Suspense>
                            </Box>
                        </AuthWrapper>

                    </Box>
                </FirestoreProvider>
            </AuthProvider>
        </ChakraProvider>
    )
}